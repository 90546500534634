.project_page{
    overflow: hidden;
}
.card{
    max-width: 25rem;
}

.d-block{
    font-size:1.125rem;
    text-anchor:middle;
}

.cardss{
    display: flex;
    gap: 100px;
    justify-content: center;
    flex-wrap: wrap;
}

.proj{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px ;
}

.image{
    height: 195px;
}